import React, { useRef, useEffect, useState } from 'react';

interface SandboxedComponentProps {
  generatedCode: string;
}

const SandboxedComponent: React.FC<SandboxedComponentProps> = ({ generatedCode }) => {
  const [srcDoc, setSrcDoc] = useState('');

  useEffect(() => {
    setSrcDoc(`
      <!DOCTYPE html>
      <html>
      <head>
        <style>
          body { margin: 0; }
        </style>
      </head>
      <body>
        <div id="root"></div>
        <script>
          ${generatedCode}
        </script>
      </body>
      </html>
    `);
  }, [generatedCode]);

  return (
    <iframe
      sandbox="allow-scripts"
      style={{ width: '100%', height: '100%', border: 'none' }}
      srcDoc={srcDoc}
    />
  );
};

export default SandboxedComponent;
