import React, { useState } from 'react';
import SandboxedComponent from './SandboxedComponent';

const APIComponent: React.FC = () => {
  const [generatedCode, setGeneratedCode] = useState('');


  const [textBoxContent, setTextBoxContent] = useState('');


  const handleTextBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextBoxContent(event.target.value);
  };

  const fetchAPIComponent = async () => {
    try {
      const response = await fetch('http://localhost:3005/api/component', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'output_requirements': textBoxContent,
        }),
      });
      const data = await response.json();
      const react_component_code = data[0];
      setGeneratedCode(react_component_code);
      // render the component code
      // eslint-disable-next-line no-eval
    } catch (error) {
      console.error('Error fetching API component:', error);
    }
  };


    const [isGenerating, setIsGenerating] = useState(false);

    const handleButtonClick = async () => {
      setIsGenerating(true);
      await fetchAPIComponent();
      setIsGenerating(false);
    };



  return (
    <div style={{
      padding: '20px',
      background: '#f0f0f0',
      border: '1px solid #ccc',
      borderRadius: '5px',
      margin: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <label htmlFor="textbox" style={{ marginBottom: '10px' }}>What do you want to make?</label>
      <input
        type="text"
        id="textbox"
        value={textBoxContent}
        onChange={handleTextBoxChange}
        style={{
          padding: '5px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          marginBottom: '10px',
        }}
      />
    <button
      onClick={handleButtonClick}
      disabled={isGenerating}
      style={{
        padding: '25px', 
        background: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '15px', 
      }}
    >
      {isGenerating ? 'Generating...' : 'Generate Component'}
    </button>
    <SandboxedComponent generatedCode={generatedCode} />

      <h2 style={{ marginBottom: '10px' }}>Generated Code:</h2>
      <pre style={{ marginBottom: '10px' }}>
        <code>{generatedCode}</code>
      </pre>
    </div>
  );
};

export default APIComponent;